// src/Components/Home/AdminDashboard.js

import React, { Suspense, lazy } from 'react';
import GiveCredits from './GiveCredits';
import GrantDevAccess from './GrantDevAccess';
import InviteRequests from './InviteRequests';
import InvitesAccepted from "./InvitesAccepted";
import FlowManagement from './FlowManagement';
import Announcements from './Announcements';
import BugReports from './BugReports';
import AddAdmin from './AddAdmin';
import FlowReports from './FlowReports';
import DeleteAccount from './DeleteAccount';
import Header from './Header';
import SendNotification from "./SendNotification";
// Lazy load the Metrics component
const Metrics = lazy(() => import('./Metrics'));

const AdminDashboard = () => {
  return (
    <div style={{
      height:'calc(100vh - 20px',
      overflowY:'scroll',
    }}>
      <Header/>
    <div style={styles.container}>
      <h1 style={styles.heading}>Admin Dashboard</h1>

      <GiveCredits />
      <GrantDevAccess />
      <InviteRequests />
      <InvitesAccepted/>
      <FlowManagement />
       
      {/* Lazy loaded Metrics with Suspense fallback */}
      <Suspense fallback={<div>Loading Metrics...</div>}>
        <Metrics />
      </Suspense>

      <Announcements />
      <BugReports />
      <AddAdmin/>
      <FlowReports/>
      <SendNotification/>
      <DeleteAccount />
    </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'quicksand',
    background:'#1c1c1c',
    marginTop:20
  },
  heading: {
    textAlign: 'center',
    marginBottom: '40px',
    fontFamily:'quicksand', fontSize:20,
    color:'white',
    fontWeight:'bold'
  },
};

export default AdminDashboard;
