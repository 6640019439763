// src/Components/Home/InviteRequests.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

/**
 * Capitalizes the first letter of each word in a name, lowercasing the rest.
 * E.g.: "brandon day" => "Brandon Day", "JOHN" => "John"
 */
const formatFullName = (rawName = '') => {
  const parts = rawName.trim().split(/\s+/); // split on one or more spaces
  return parts
    .map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    )
    .join(' ');
};

// Number of inviteRequests to fetch per page.
const PAGE_SIZE = 5; // Try 1 or 2 for testing.

const InviteRequests = () => {
  const db = firebase.firestore();

  // Current page’s invites
  const [inviteRequests, setInviteRequests] = useState([]);
  // For success or error messages
  const [inviteMessage, setInviteMessage] = useState('');
  const [error, setError] = useState('');
  // Tracks which invite is being processed (so the button can show “Processing…”)
  const [processingInviteId, setProcessingInviteId] = useState(null);

  // Are we loading data from Firestore right now?
  const [loading, setLoading] = useState(false);

  // Array of pages: each has { docs, lastDoc }
  const [pages, setPages] = useState([]);
  // Current page index in pages[]
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    // Load the first page immediately
    loadPage('next', true);
    // eslint-disable-next-line
  }, []);

  /**
   * Loads a page of docs in 'inviteRequests' with pagination.
   * @param {'next'|'prev'} direction
   * @param {boolean} initialLoad - if true, we won't do any offset
   */
  const loadPage = async (direction = 'next', initialLoad = false) => {
    try {
      setLoading(true);
      setInviteMessage('');
      setError('');

      // If going “prev,” move to the previous page (if any) in our local stack
      if (direction === 'prev') {
        if (pageIndex > 0) {
          setPageIndex(pageIndex - 1);
          setInviteRequests(pages[pageIndex - 1].docs);
        }
        setLoading(false);
        return;
      }

      // If going “next” but we already have a next page in the stack,
      // just move forward without a new query
      if (direction === 'next' && !initialLoad && pageIndex < pages.length - 1) {
        setPageIndex(pageIndex + 1);
        setInviteRequests(pages[pageIndex + 1].docs);
        setLoading(false);
        return;
      }

      // Build the query for the next page
      let query = db.collection('inviteRequests')
        .orderBy('createdAt', 'desc')
        .limit(PAGE_SIZE);

      // If we’re not on the first load, we have a "lastDoc" for the current page
      if (!initialLoad && pages.length > 0 && pageIndex === pages.length - 1) {
        const lastDoc = pages[pageIndex].lastDoc;
        if (lastDoc) {
          query = query.startAfter(lastDoc);
        }
      }

      const snapshot = await query.get();
      if (!snapshot.empty) {
        const docs = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        const lastDoc = snapshot.docs[snapshot.docs.length - 1];

        // Create a new page object
        const newPage = { docs, lastDoc };

        // Insert into pages, discarding any "forward" pages if we've gone back
        const newPages = [...pages.slice(0, pageIndex + 1), newPage];
        setPages(newPages);
        setPageIndex(newPages.length - 1);
        setInviteRequests(docs);
      } else {
        // We have no more docs
        setError("No more invite requests to display.");
      }
    } catch (err) {
      console.error("Error loading page:", err);
      setError("Failed to load invite requests. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Simple reload: resets pagination and fetches first page again
  const handleReload = () => {
    setPages([]);
    setPageIndex(0);
    setInviteRequests([]);
    loadPage('next', true);
  };

  // Send verification email (existing code)
  const handleSendVerificationEmailAccept = async (email, fullname) => {
    try {
      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/sendCustomInviteEmailAcceptedMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            displayName: fullname,
          }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to send verification email.");
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error("Error sending email:", error.message);
      throw error;
    }
  };

  // Accept invite
  const handleAcceptInvite = async (inviteId, inviteEmail, fullname) => {
    setInviteMessage('');
    setError('');
    setProcessingInviteId(inviteId);

    try {
      const inviteEmailTrimmed = inviteEmail.toLowerCase().trim();
      // Look for the invite doc
      const inviteSnapshot = await db.collection('inviteRequests')
        .where('inviteEmail', '==', inviteEmailTrimmed)
        .limit(1)
        .get();

      if (inviteSnapshot.empty) {
        setError('Invite not found in inviteRequests collection.');
        setProcessingInviteId(null);
        return;
      }

      const inviteDoc = inviteSnapshot.docs[0];
      const inviteRef = db.collection('invites').doc(inviteDoc.id);

      // Rate-limiting check
      const inviteDocSnapshot = await inviteRef.get();
      if (inviteDocSnapshot.exists) {
        const lastAcceptedAt = inviteDocSnapshot.data()?.acceptedAt?.toDate();
        const now = new Date();
        if (lastAcceptedAt && now - lastAcceptedAt < 60 * 1000) { // 1 minute
          setError('Too many attempts. Please wait a moment before trying again.');
          setProcessingInviteId(null);
          return;
        }
      }

      // Send verification email
      try {
        await handleSendVerificationEmailAccept(inviteEmailTrimmed, fullname);
      } catch (emailError) {
        if (emailError.message.includes("TOO_MANY_ATTEMPTS_TRY_LATER")) {
          setError("Too many attempts to send verification emails. Please wait a moment.");
        } else {
          setError("Failed to send verification email. Please try again.");
        }
        setProcessingInviteId(null);
        return;
      }

      // Update or create doc in 'invites'
      if (!inviteDocSnapshot.exists) {
        await inviteRef.set({
          fullname,
          inviteAccepted: true,
          acceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
          redeemedByEmail: inviteEmailTrimmed,
        }, { merge: true });
      } else {
        await inviteRef.update({
          inviteAccepted: true,
          acceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      // Remove from 'inviteRequests'
      await db.collection('inviteRequests').doc(inviteId).delete();

      // Also remove from local array so it disappears immediately
      setInviteRequests((prev) => prev.filter((req) => req.id !== inviteId));

      setInviteMessage(`Invite for ${inviteEmailTrimmed} has been accepted.`);
    } catch (err) {
      console.error("Error accepting invite:", err);
      setError('Failed to accept invite. Please try again.');
    } finally {
      setProcessingInviteId(null);
    }
  };

  // Reject invite
  const handleRejectInvite = async (inviteId) => {
    setInviteMessage('');
    setError('');
    setProcessingInviteId(inviteId);

    try {
      await db.collection('inviteRequests').doc(inviteId).delete();
      // Also remove from local array
      setInviteRequests((prev) => prev.filter((req) => req.id !== inviteId));

      setInviteMessage('Invite request has been rejected and removed.');
    } catch (err) {
      console.error("Error rejecting invite:", err);
      setError('Failed to reject invite. Please try again.');
    } finally {
      setProcessingInviteId(null);
    }
  };

  // React-window row
  const Row = React.memo(({ index, style }) => {
    const invite = inviteRequests[index];
    if (!invite) return null;

    const isProcessing = processingInviteId === invite.id;

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Full Name:</strong>{' '}
          {invite.fullname ? formatFullName(invite.fullname) : 'N/A'}
          <br />
          <strong>Email:</strong> {invite.inviteEmail || 'N/A'}
          <br />
          <div style={{ height:50, overflowY:'scroll' }}>
            <strong>Info:</strong> {invite.inviteInfo || 'N/A'}
          </div>
          <br />
          <strong>Timestamp:</strong>{' '}
          {invite.createdAt ? invite.createdAt.toDate().toLocaleString() : 'N/A'}
        </div>
        <div style={styles.buttonGroup}>
          <button
            onClick={() => handleAcceptInvite(invite.id, invite.inviteEmail, invite.fullname)}
            style={{ ...styles.button, ...styles.acceptButton }}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Accept'}
          </button>
          <button
            onClick={() => handleRejectInvite(invite.id)}
            style={{ ...styles.button, ...styles.rejectButton }}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Reject'}
          </button>
        </div>
      </div>
    );
  });

  // If no pages loaded yet & still loading => show spinner
  if (loading && pages.length === 0) {
    return (
      <section style={styles.section}>
        <style>{
          `@keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }`
        }</style>
        <h2 style={{ marginBottom:20 }}>Pending Invite Requests</h2>
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
          <p>Loading pending invite requests...</p>
        </div>
        {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
      </section>
    );
  }

  return (
    <section style={styles.section}>
      <h2 style={{ marginBottom:20 }}>Pending Invite Requests</h2>

      {/* Pagination + Reload */}
      <div style={styles.pagination}>
        <button
          style={styles.pageButton}
          onClick={() => loadPage('prev')}
          disabled={loading || pageIndex === 0}
        >
          &laquo; Back
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {pageIndex + 1} of {pages.length}
        </span>
        <button
          style={styles.pageButton}
          onClick={() => loadPage('next')}
          disabled={loading}
        >
          Next &raquo;
        </button>
        {/* Reload button */}
        <button
          style={styles.pageButton}
          onClick={handleReload}
          disabled={loading}
        >
          Reload
        </button>
      </div>

      {inviteRequests.length === 0 ? (
        <p>No pending invite requests.</p>
      ) : (
        <List
          height={400}
          itemCount={inviteRequests.length}
          itemSize={100}
          width={'100%'}
        >
          {Row}
        </List>
      )}
      {loading && <p>Loading...</p>}

      {inviteMessage && <p style={styles.message}>{inviteMessage}</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  spinner: {
    width: '40px',
    height: '40px',
    border: '5px solid #ccc',
    borderTop: '5px solid #28A745',
    borderRadius: '50%',
    marginBottom: '10px',
    animation: 'spin 1s linear infinite',
  },
  pagination: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  pageButton: {
    backgroundColor: '#28A745',
    color: '#fff',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
    margin: '0 5px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  button: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },
  acceptButton: {
    backgroundColor: '#28A745',
    color: '#fff',
  },
  rejectButton: {
    backgroundColor: '#DC3545',
    color: '#fff',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
};

export default InviteRequests;
