// src/Components/Home/Announcements.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

// Function to add a notification when an announcement is made
const addInviteCodeNotification = async (sender, receiver, message, flowId) => {
  const newNotification = {
    sender,
    receiver,
    type: "flowroom",
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    additionalData: { message, flowId },
    isSeen: false
  };
  await firebase.firestore().collection("notifications").add(newNotification);
};

const PAGE_SIZE = 5; // Change to 1, 2, or more for local pagination tests

const Announcements = () => {
  const db = firebase.firestore();
  const [announcement, setAnnouncement] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [announcementMessage, setAnnouncementMessage] = useState('');
  const [error, setError] = useState('');

  // Pagination state
  const [pageIndex, setPageIndex] = useState(0);

  // Define the maximum character limit
  const MAX_CHAR_LIMIT = 350;

  useEffect(() => {
    console.log("Fetching site announcements...");
    const unsubscribe = db.collection('siteMessage')
      .orderBy('createdAt', 'desc') // Keeps your existing ordering
      .onSnapshot(
        (snapshot) => {
          const msgs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setAnnouncements(msgs);
        },
        (err) => {
          console.error("Error fetching announcements:", err);
          setError('Failed to fetch announcements.');
        }
      );

    return () => unsubscribe();
  }, [db]);

  const handleSubmitAnnouncement = async () => {
    setAnnouncementMessage('');
    setError('');

    if (!announcement.trim()) {
      setAnnouncementMessage('Please provide a message.');
      return;
    }

    if (announcement.length > MAX_CHAR_LIMIT) {
      setError(`Announcement exceeds the maximum limit of ${MAX_CHAR_LIMIT} characters.`);
      return;
    }

    try {
      await db.collection('siteMessage').add({
        message: announcement.trim(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setAnnouncementMessage('Announcement has been submitted.');
      await addInviteCodeNotification("flowroom", "all_flowroo_musers_XYRGT-TEXTX", announcement.trim(), "noid");
      setAnnouncement('');
    } catch (err) {
      console.error("Error submitting announcement:", err);
      setAnnouncementMessage('Failed to submit announcement. Please try again.');
    }
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    setAnnouncementMessage('');
    setError('');

    try {
      await db.collection('siteMessage').doc(announcementId).delete();
      setAnnouncementMessage('Announcement has been deleted.');
    } catch (err) {
      console.error("Error deleting announcement:", err);
      setAnnouncementMessage('Failed to delete announcement. Please try again.');
    }
  };

  // Handle announcement input with character limit
  const handleAnnouncementChange = (e) => {
    const inputAnnouncement = e.target.value;
    if (inputAnnouncement.length <= MAX_CHAR_LIMIT) {
      setAnnouncement(inputAnnouncement);
    } else {
      setAnnouncement(inputAnnouncement.slice(0, MAX_CHAR_LIMIT));
    }
  };

  // Local Pagination: slice announcements into "pages"
  const totalPages = Math.ceil(announcements.length / PAGE_SIZE);
  const startIndex = pageIndex * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentPageAnnouncements = announcements.slice(startIndex, endIndex);

  // Modified Row component to include the date, referencing currentPageAnnouncements
  const Row = React.memo(({ index, style }) => {
    const msg = currentPageAnnouncements[index];
    if (!msg) return null;

    const { id, message, createdAt } = msg;
    // Convert Firestore Timestamp to JavaScript Date
    const date = createdAt ? createdAt.toDate() : new Date();
    // Format the date to a readable string
    const formattedDate = date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div style={styles.messageContainer}>
          <span style={styles.messageText}>{message}</span>
          <span style={styles.dateText}>{formattedDate}</span>
        </div>
        <button
          onClick={() => handleDeleteAnnouncement(id)}
          style={styles.deleteButton}
        >
          Delete
        </button>
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2>Site-wide Announcements</h2>
      <div style={styles.inputGroup}>
        <textarea
          placeholder="Write your announcement here..."
          value={announcement}
          onChange={handleAnnouncementChange}
          style={styles.textarea}
          maxLength={MAX_CHAR_LIMIT}
        />
        <div style={styles.charCountContainer}>
          <span style={styles.charCount}>
            {announcement.length} / {MAX_CHAR_LIMIT}
          </span>
        </div>
        <button onClick={handleSubmitAnnouncement} style={styles.button}>
          Submit Announcement
        </button>
      </div>
      {announcementMessage && <p style={styles.message}>{announcementMessage}</p>}

      {/* Local Pagination Controls */}
      <div style={styles.pagination}>
        <button
          style={styles.pageButton}
          onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
          disabled={pageIndex === 0}
        >
          &laquo; Back
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {pageIndex + 1} of {Math.max(totalPages, 1)}
        </span>
        <button
          style={styles.pageButton}
          onClick={() => setPageIndex(Math.min(totalPages - 1, pageIndex + 1))}
          disabled={pageIndex >= totalPages - 1 || totalPages === 0}
        >
          Next &raquo;
        </button>
      </div>

      <List
        height={400}
        itemCount={currentPageAnnouncements.length}
        itemSize={80}
        width={'100%'}
      >
        {Row}
      </List>
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    position: 'relative',
  },
  textarea: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minHeight: '80px',
    resize: 'vertical',
    fontSize: '16px',
  },
  charCountContainer: {
    alignSelf: 'flex-end',
    marginTop: '-10px',
    marginBottom: '10px',
  },
  charCount: {
    fontSize: '12px',
    color: '#666',
  },
  button: {
    alignSelf: 'flex-start',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  messageText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  dateText: {
    fontSize: '12px',
    color: '#666',
    marginTop: '4px',
  },
  deleteButton: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#DC3545',
    color: '#fff',
    cursor: 'pointer',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
  pagination: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  pageButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    margin: '0 5px',
  },
};

export default Announcements;
