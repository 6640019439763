// src/Components/Home/InvitesAccepted.js

import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

/**
 * Helper: remove extra spaces and force lowercase,
 * ensuring consistent email matching.
 */
const normalizeEmail = (email = '') => {
  return email.trim().toLowerCase();
};

/**
 * Helper: chunk an array into subarrays of a given size (up to 10 for Firestore).
 */
const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

/**
 * Capitalizes each word in a full name:
 *   - Splits on whitespace
 *   - For each word, uppercase the first letter, lowercase the rest
 */
const formatFullName = (rawName = '') => {
  const parts = rawName.trim().split(/\s+/);
  return parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');
};

const PAGE_SIZE = 5; // <= change to 2, 5, etc. as you wish

const InvitesAccepted = () => {
  const db = firebase.firestore();

  // We store the current page's invites
  const [acceptedInvites, setAcceptedInvites] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // We’ll store pages in a stack so we can go forward and backward
  // Each page: { invites: [...], lastDoc: docSnapshot }
  const [pages, setPages] = useState([]);
  // Current page index in that stack
  const [pageIndex, setPageIndex] = useState(0);

  // Rate-limiting map for re-sending invites
  const lastSendTimesRef = useRef({});

  useEffect(() => {
    // Load the first page on mount
    loadPage();
    // eslint-disable-next-line
  }, []);

  // Loads a specific page in the stack or fetches a new one if needed
  const loadPage = async (direction = 'next') => {
    try {
      setLoading(true);

      if (direction === 'prev') {
        // Move one page back in the stack
        if (pageIndex > 0) {
          setPageIndex(pageIndex - 1);
          setAcceptedInvites(pages[pageIndex - 1].invites);
        }
        setLoading(false);
        return;
      }

      // If we already are at a certain page and want to go forward (and it's in the stack)
      if (direction === 'next' && pageIndex < pages.length - 1) {
        setPageIndex(pageIndex + 1);
        setAcceptedInvites(pages[pageIndex + 1].invites);
        setLoading(false);
        return;
      }

      // Otherwise, we need to fetch a new page
      let query = db.collection('invites')
        .where('inviteAccepted', '==', true)
        .orderBy('acceptedAt', 'desc')
        .limit(PAGE_SIZE);

      // If there's an existing lastDoc in the *current* page, we start after it
      if (pages.length > 0 && pageIndex === pages.length - 1) {
        const lastDoc = pages[pageIndex].lastDoc;
        if (lastDoc) {
          query = query.startAfter(lastDoc);
        }
      }

      const snapshot = await query.get();

      if (!snapshot.empty) {
        // Process the docs
        const invites = snapshot.docs.map((doc) => {
          const data = doc.data() || {};
          return {
            id: doc.id,
            ...data,
            redeemedByEmail: data.redeemedByEmail
              ? normalizeEmail(data.redeemedByEmail)
              : null,
          };
        });

        // We'll record the last doc from this new page
        const lastDoc = snapshot.docs[snapshot.docs.length - 1];

        // Build a unique set of normalized emails from these invites
        const emailSet = new Set(
          invites.map((inv) => inv.redeemedByEmail).filter(Boolean)
        );
        const emailArray = Array.from(emailSet);

        // Fetch matching user docs in chunks of 10
        let usersDataMap = {};
        if (emailArray.length > 0) {
          const chunkedEmails = chunkArray(emailArray, 10);
          for (const chunk of chunkedEmails) {
            const userSnapshot = await db.collection('users')
              .where('email', 'in', chunk)
              .get();
            userSnapshot.docs.forEach((d) => {
              const userData = d.data() || {};
              const normalizedUserEmail = normalizeEmail(userData.email);
              usersDataMap[normalizedUserEmail] = userData;
            });
          }
        }

        // Merge user info
        const mergedData = invites.map((inv) => {
          const userDoc = inv.redeemedByEmail
            ? usersDataMap[inv.redeemedByEmail] || null
            : null;
          return { ...inv, userDoc };
        });

        // Add this page to our stack
        const newPage = { invites: mergedData, lastDoc };
        const newPages = [...pages.slice(0, pageIndex + 1), newPage];
        setPages(newPages);
        setPageIndex(newPages.length - 1);
        setAcceptedInvites(mergedData);
      } else {
        // No more docs
        setError("No more invites to display.");
      }
    } catch (err) {
      console.error("Error loading page:", err);
      setError("Failed to load invites. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // NEW: Simple reload (resets pagination and loads from the start)
  const handleReload = () => {
    setPages([]);
    setPageIndex(0);
    setAcceptedInvites([]);
    setError('');
    loadPage('next'); 
  };

  // Row component for react-window
  const Row = React.memo(({ index, style }) => {
    const invite = acceptedInvites[index];
    const userSignedUp = !!invite.userDoc; // true if there's a matching user doc

    // Full name from userDoc
    const userFullName =
      userSignedUp && invite.userDoc.fullname
        ? formatFullName(invite.userDoc.fullname)
        : null;

    // Full name from the invite doc (for "Not signed up")
    const inviteFullName = invite.fullname
      ? formatFullName(invite.fullname)
      : null;

    // If user doc has a "date" field as Firestore Timestamp
    const userSignUpDate =
      userSignedUp && invite.userDoc.date && invite.userDoc.date.toDate
        ? invite.userDoc.date.toDate().toLocaleString()
        : null;

    // Invite accepted date
    const inviteAcceptedDate = invite.acceptedAt
      ? invite.acceptedAt.toDate().toLocaleString()
      : 'N/A';

    // For the ephemeral "Invite re-sent!" display
    const [buttonText, setButtonText] = useState("Re-send invite accepted email");
    const [isCooldown, setIsCooldown] = useState(false);
    const cooldownRef = useRef(null);

    useEffect(() => {
      return () => {
        if (cooldownRef.current) {
          clearTimeout(cooldownRef.current);
        }
      };
    }, []);

    // The function to call your Cloud Function
    const handleResendInvite = async () => {
      const now = Date.now();
      const lastSendTime = lastSendTimesRef.current[invite.id] || 0;
      const RATE_LIMIT = 10000; // 10 seconds

      if (now - lastSendTime < RATE_LIMIT) {
        setError("Please wait a few seconds before re-sending again.");
        return;
      }
      lastSendTimesRef.current[invite.id] = now;

      const displayName = userFullName || "there";
      const email = invite.redeemedByEmail || "";

      console.log("Re-sending invite email to:", email, displayName);

      try {
        const response = await fetch(
          "https://us-central1-flowroom-fd862.cloudfunctions.net/sendCustomInviteEmailAcceptedMessage",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, displayName }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to send verification email.");
        }

        const result = await response.json();
        console.log("Email re-sent successfully:", result.message);
        setError("");

        setButtonText("Invite re-sent!");
        setIsCooldown(true);
        cooldownRef.current = setTimeout(() => {
          setButtonText("Re-send invite accepted email");
          setIsCooldown(false);
        }, 2000);

      } catch (error) {
        console.error("Error re-sending invite email:", error);
        alert(`Failed to re-send invite email. Error: ${error.message}`);
      }
    };

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Email:</strong> {invite.redeemedByEmail || 'N/A'}
          <br />
          {userSignedUp ? (
            <>
              <strong>Status:</strong> Signed Up
              <br />
              {userFullName && (
                <>
                  <strong>Full Name:</strong> {userFullName}
                  <br />
                </>
              )}
              <strong>User Signed Up Date:</strong> {userSignUpDate || 'N/A'}
            </>
          ) : (
            <>
              <strong>Status:</strong> Not signed up
              <br />
              {inviteFullName && (
                <>
                  <strong>Full Name:</strong> {inviteFullName}
                  <br />
                </>
              )}
            </>
          )}
          <br />
          <strong>Invite Accepted Date:</strong> {inviteAcceptedDate}
        </div>

        {!userSignedUp && (
          <button
            style={{
              ...styles.resendButton,
              opacity: isCooldown ? 0.7 : 1,
              cursor: isCooldown ? 'not-allowed' : 'pointer'
            }}
            onClick={handleResendInvite}
            disabled={isCooldown}
          >
            {buttonText}
          </button>
        )}
      </div>
    );
  });

  // Loading spinner
  if (loading && pages.length === 0) {
    return (
      <section style={styles.section}>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg);}
            100% { transform: rotate(360deg);}
          }
        `}</style>
        <h2 style={{marginBottom:20}}>Accepted Invites & Successful Sign Ups</h2>
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
          <p>Loading accepted invites. Please wait...</p>
        </div>
        {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
      </section>
    );
  }

  // Renders the current page's invites
  return (
    <section style={styles.section}>
      <h2 style={{marginBottom:20}}>Accepted Invites & Successful Sign Ups</h2>

      {/* Pagination Controls */}
      <div style={styles.pagination}>
        <button
          style={styles.pageButton}
          onClick={() => loadPage('prev')}
          disabled={loading || pageIndex === 0}
        >
          &laquo; Back
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {pageIndex + 1} of {pages.length}
        </span>
        <button
          style={styles.pageButton}
          onClick={() => loadPage('next')}
          disabled={loading}
        >
          Next &raquo;
        </button>
        {/* NEW: Reload button */}
        <button
          style={styles.pageButton}
          onClick={handleReload}
          disabled={loading}
        >
          Reload
        </button>
      </div>

      {acceptedInvites.length === 0 ? (
        <p>No accepted invites found.</p>
      ) : (
        <List
          height={400}
          itemCount={acceptedInvites.length}
          itemSize={150}
          width="100%"
        >
          {Row}
        </List>
      )}

      {loading && <p>Loading...</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  spinner: {
    width: '40px',
    height: '40px',
    border: '5px solid #ccc',
    borderTop: '5px solid #007bff',
    borderRadius: '50%',
    marginBottom: '10px',
    animation: 'spin 1s linear infinite',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
  },
  resendButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '6px 12px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
  },
  pageButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
    margin: '0 5px',
  },
  pagination: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
};

export default InvitesAccepted;
