import { faCheck, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { firebase } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const [otp, setOtp] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const [verifyCooldown, setVerifyCooldown] = useState(0);

  const email = firebase.auth().currentUser.email; // Replace with dynamic user email if available
  const navigate = useNavigate();

  const OTP_MAX_LENGTH = 6;

  // Handle OTP Input Validation
  const handleOtpChange = (value) => {
    // Restrict input to numeric characters only
    if (!/^\d*$/.test(value)) {
      setVerificationError("Only numbers are allowed.");
      return;
    }
    // Limit input length to OTP_MAX_LENGTH
    if (value.length > OTP_MAX_LENGTH) {
      setVerificationError(`Code cannot exceed ${OTP_MAX_LENGTH} digits.`);
      return;
    }
    setVerificationError(""); // Clear errors if input is valid
    setOtp(value);
  };

  // Send OTP Request to Cloud Function
  const sendOtpRequest = async () => {
    if (resendCooldown > 0) {
      setVerificationError(`Please wait ${resendCooldown} seconds before resending.`);
      return;
    }

    try {
      setIsLoading(true);
      const displayName = "User"; // Replace with dynamic user display name if available
      const userId = firebase.auth().currentUser?.uid || "tempUserId";

      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/sendCustomVerifyEmailMessage",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, displayName, userId }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("OTP sent successfully:", data.message);
        setOtpSent(true);
        setResendCooldown(60); // Start cooldown timer
      } else {
        console.error("Failed to send OTP:", data.error);
        setVerificationError(data.error || "Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setVerificationError("Failed to send OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Verify OTP and Mark Email as Verified in Firebase Auth
  const verifyOtpAndMarkEmailVerified = async () => {
    if (!otp) {
      setVerificationError("The code field cannot be empty.");
      return;
    }

    if (verifyCooldown > 0) {
      setVerificationError(`Please wait ${verifyCooldown} seconds before trying again.`);
      return;
    }

    try {
      setIsLoading(true);

      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        throw new Error("No authenticated user found. Please log in.");
      }

      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/verifyOtpAndMarkEmailVerified",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: currentUser.uid, otp }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log(data.message);

        // Reload the user's session to reflect changes
        await currentUser.reload();

        if (firebase.auth().currentUser.emailVerified) {
          setEmailVerified(true);
          // navigate("/ProfileSetup");
        } else {
          throw new Error("Failed to verify email. Please try again.");
        }
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
      setVerificationError(error.message);
    } finally {
      setIsLoading(false);
      setVerifyCooldown(10); // Set cooldown for Verify Email button
    }
  };

  // Countdown Timers
  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setTimeout(() => setResendCooldown((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendCooldown]);

  useEffect(() => {
    if (verifyCooldown > 0) {
      const timer = setTimeout(() => setVerifyCooldown((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [verifyCooldown]);

  // Button Styles
  const buttonStyles = {
    base: {
      padding: "10px 20px",
      fontSize: 16,
      border: "none",
      borderRadius: 5,
      cursor: "pointer",
      transition: "background-color 0.3s ease, transform 0.1s ease",
    },
    hover: {
      backgroundColor: "#3cb371",
    },
    active: {
      transform: "scale(0.95)",
    },
    disabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
    },
  };

  // Render Logic
  if (emailVerified) {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ flex: "1", display: "flex", background: "#323232", width: "100%" }}>
          <div
            style={{
              height: "calc(100vh - 70px)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: 50,
            }}
          >
            <div
              style={{
                height: 250,
                width: "100%",
                maxHeight: 300,
                maxWidth: 400,
                background: "#1C1C1C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: 15,
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                color="#FEFFFE"
                style={{ marginBottom: 10, fontSize: 80 }}
              />
              <p style={{ color: "#4FD975", fontFamily: "quicksand", fontSize: 20 }}>
                Email Verified!
              </p>
            </div>
            <button
                onClick={()=>{
                  navigate('/ProfileSetup')
                }}
                style={{
                  ...buttonStyles.base,
                  backgroundColor: "rgb(79, 217, 117)",
                  marginTop:20,
                  fontWeight:'bold',
                  fontFamily:'quicksand',
                  color:'rgb(50, 50, 50)'
                }}
              >
                Continue to Profile Setup
              </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ flex: "1", display: "flex", background: "#323232", width: "100%" }}>
        <div
          style={{
            height: "calc(100vh - 70px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 50,
          }}
        >
          {!otpSent ? (
            <>
            <p style={{ color: "#FFFFFF", fontFamily: "quicksand", marginBottom: 20 }}>
                You must verify your email address before continuing to setup your account.
              </p>
              <p style={{ color: "#FFFFFF", fontFamily: "quicksand", marginBottom: 20 }}>
                To verify your email, click the button below to request a verification code.
                <br />
                The code will be sent to: <strong>{email}</strong>
              </p>
              <button
                onClick={sendOtpRequest}
                disabled={resendCooldown > 0 || isLoading}
                style={{
                  ...buttonStyles.base,
                  backgroundColor: isLoading
                    ? buttonStyles.disabled.backgroundColor
                    : resendCooldown > 0
                    ? buttonStyles.disabled.backgroundColor
                    : "#4FD975",
                    display:'flex'
                }}
              >
                    <p style={{fontFamily:"quicksand", fontSize:12, fontWeight:'bold', color:'rgb(50, 50, 50)'}}>Send Verification Code</p>
                  <span style={{marginLeft:10}}>
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: 8 }} />
                ) : ""}
                </span>
              </button>
            </>
          ) : (
            <>
            <p style={{ color: "#FFFFFF", fontFamily: "quicksand", marginBottom: 20 }}>
               An email verification code has been sent to the email address you provided.
              </p>
              <p style={{ color: "#FFFFFF", fontFamily: "quicksand", marginBottom: 20 }}>
               Enter the verification code sent to your email to continue setting up your account: 
              </p>
              <input
                type="text"
                value={otp}
                onChange={(e) => handleOtpChange(e.target.value)}
                placeholder="Enter code"
                style={{
                  padding: "10px",
                  fontSize: 16,
                  borderRadius: 5,
                  border: "1px solid #ccc",
                  marginBottom: 20,
                }}
              />
              <button
                onClick={verifyOtpAndMarkEmailVerified}
                disabled={isLoading || verifyCooldown > 0}
                style={{
                  ...buttonStyles.base,
                  backgroundColor: isLoading || verifyCooldown > 0 ? "#ccc" : "#4FD975",
                  fontWeight:'bold',
                  color:'rgb(50, 50, 50)'
                }}
              >
                {isLoading
                  ? "Validating..."
                  : `Verify Email${verifyCooldown > 0 ? ` (${verifyCooldown}s)` : ""}`}
              </button>
              {isLoading ? (<p style={{color: "#FFFFFF", fontFamily: "quicksand", marginTop:20}}>Validating code, please wait...</p>):""}
              <p style={{ color: "#FFFFFF", fontFamily: "quicksand", marginTop: 20, marginBottom:20 }}>
                {resendCooldown > 0
                  ? `Didn't receive a code or it expired? You can send another verification code in ${resendCooldown} seconds.`
                  : "Didn't receive a code or it expired?"}
              </p>
              <button
                onClick={sendOtpRequest}
                disabled={resendCooldown > 0}
                style={{
                  ...buttonStyles.base,
                  backgroundColor: resendCooldown > 0 ? "#ccc" : "#4FD975",
                }}
              >
                Resend Verification Code
              </button>
            </>
          )}
          {verificationError && (
            <p style={{ color: "red", fontFamily: "quicksand", marginTop: 20 }}>{verificationError}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(EmailVerification);
