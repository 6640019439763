// src/Components/Home/BugReports.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

const PAGE_SIZE = 5; // Try 1 or 2 for testing local pagination

const BugReports = () => {
  const db = firebase.firestore();
  const [bugReports, setBugReports] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [processingReportId, setProcessingReportId] = useState(null); // To track which report is being processed

  // For local pagination
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    console.log("Fetching bug reports...");
    const unsubscribe = db.collection('help')
      .orderBy('timestamp', 'desc')
      .onSnapshot(
        snapshot => {
          const reports = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log("Fetched bug reports:", reports);
          setBugReports(reports);
        },
        err => {
          console.error("Error fetching bug reports:", err);
          setError('Failed to fetch bug reports.');
        }
      );

    return () => unsubscribe();
  }, [db]);

  const handleMarkAsRead = async (reportId) => {
    setMessage('');
    setError('');
    setProcessingReportId(reportId);

    try {
      await db.collection('help').doc(reportId).update({
        read: true,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setMessage('Bug report marked as read.');
    } catch (err) {
      console.error("Error marking bug report as read:", err);
      setError('Failed to mark bug report as read. Please try again.');
    } finally {
      setProcessingReportId(null);
    }
  };

  // 1) Compute total pages based on how many docs we have
  const totalPages = Math.ceil(bugReports.length / PAGE_SIZE);

  // 2) Slice the array to show only the current page
  const startIndex = pageIndex * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentPageReports = bugReports.slice(startIndex, endIndex);

  // Row component for react-window, referencing currentPageReports
  const Row = React.memo(({ index, style }) => {
    const report = currentPageReports[index];
    if (!report) return null;

    const isProcessing = processingReportId === report.id;
    const isUnread = !report.read;

    return (
      <div
        style={{
          ...style,
          ...styles.listItem,
          backgroundColor: isUnread ? '#fff3cd' : '#d4edda'
        }}
      >
        <div style={styles.reportInfo}>
          <h3 style={{ margin: '0 0 5px 0' }}>{report.title}</h3>
          <p style={{ margin: '0 0 5px 0' }}>
            <strong>User:</strong> {report.currentUser}
          </p>
          <p style={{ margin: '0 0 5px 0' }}>
            <strong>Email:</strong> {report.email}
          </p>
          <p style={{ margin: '0 0 5px 0' }}>
            <strong>Time:</strong>{' '}
            {report.timestamp
              ? report.timestamp.toDate().toLocaleString()
              : 'N/A'
            }
          </p>
          <p style={{ margin: '0 0 10px 0' }}>
            <strong>Description:</strong> {report.description}
          </p>
        </div>
        {isUnread && (
          <button
            onClick={() => handleMarkAsRead(report.id)}
            style={styles.markAsReadButton}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Mark as Read'}
          </button>
        )}
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2 style={{marginBottom:20}}>Bug Reports</h2>

      {/* Local Pagination Controls */}
      <div style={styles.pagination}>
        <button
          style={styles.pageButton}
          onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
          disabled={pageIndex === 0}
        >
          &laquo; Back
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {pageIndex + 1} of {Math.max(totalPages, 1)}
        </span>
        <button
          style={styles.pageButton}
          onClick={() => setPageIndex(Math.min(totalPages - 1, pageIndex + 1))}
          disabled={pageIndex >= totalPages - 1 || totalPages === 0}
        >
          Next &raquo;
        </button>
      </div>

      {currentPageReports.length === 0 ? (
        <p>No bug reports found.</p>
      ) : (
        <List
          height={400}
          itemCount={currentPageReports.length}
          itemSize={150}
          width="100%"
        >
          {Row}
        </List>
      )}

      {message && <p style={styles.message}>{message}</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
  },
  pagination: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  pageButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    margin: '0 5px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '15px',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
  },
  reportInfo: {
    flex: '1',
    marginRight: '20px',
  },
  markAsReadButton: {
    padding: '10px 15px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    height: 'fit-content',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
};

export default BugReports;
