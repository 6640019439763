// src/Components/Home/GiveCredits.js

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FixedSizeList as List } from 'react-window';

const PAGE_SIZE_REQUESTS = 5; // Number of credit requests per page
const PAGE_SIZE_USERS = 5;    // Number of users per page

const GiveCredits = () => {
  const db = firebase.firestore();

  // State for Credit Requests
  const [creditRequests, setCreditRequests] = useState([]);
  const [loadingRequests, setLoadingRequests] = useState(true);
  const [requestMessage, setRequestMessage] = useState('');
  const [requestError, setRequestError] = useState('');

  // Pagination for Requests
  const [pageIndexRequests, setPageIndexRequests] = useState(0);

  // State for Users and Credits
  const [users, setUsers] = useState([]);
  const [creditsMap, setCreditsMap] = useState({});
  const [usersWithCredits, setUsersWithCredits] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [userMessage, setUserMessage] = useState('');
  const [userError, setUserError] = useState('');

  // Pagination for Users
  const [pageIndexUsers, setPageIndexUsers] = useState(0);

  // Tracks loading state for each credit request row operation
  const [actionLoading, setActionLoading] = useState({});

  // Fetch Credit Requests
  useEffect(() => {
    const unsubscribe = db
      .collection('creditRequest')
      .where('creditRequest', '==', true)
      .onSnapshot(
        async (snapshot) => {
          const requests = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          const enhancedRequests = await Promise.all(
            requests.map(async (request) => {
              // Fetch user by username
              const userSnapshot = await db
                .collection('users')
                .where('username', '==', request.currentUser)
                .limit(1)
                .get();

              if (userSnapshot.empty) {
                return {
                  ...request,
                  userId: null,
                  currentCredits: null,
                  error: 'User not found.',
                };
              }

              const userDoc = userSnapshot.docs[0];
              const userId = userDoc.data().userId;

              // Fetch current credits
              let currentCredits = 0;
              const creditDoc = await db.collection('credits').doc(userId).get();
              if (creditDoc.exists) {
                currentCredits = creditDoc.data().credits;
              }

              return { ...request, userId, currentCredits };
            })
          );
          setCreditRequests(enhancedRequests);
          setLoadingRequests(false);
        },
        (err) => {
          console.error('Error fetching credit requests:', err);
          setRequestError('Failed to fetch credit requests.');
          setLoadingRequests(false);
        }
      );

    return () => unsubscribe();
  }, [db]);

  // Fetch Users and Credits
  useEffect(() => {
    // Listener for Users
    const unsubscribeUsers = db.collection('users').onSnapshot(
      (snapshot) => {
        const fetchedUsers = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(fetchedUsers);
        setLoadingUsers(false);
      },
      (err) => {
        console.error('Error fetching users:', err);
        setUserError('Failed to fetch users.');
        setLoadingUsers(false);
      }
    );

    // Listener for Credits
    const unsubscribeCredits = db.collection('credits').onSnapshot(
      (snapshot) => {
        const fetchedCredits = {};
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          fetchedCredits[doc.id] = data.credits; // doc.id is userId
        });
        setCreditsMap(fetchedCredits);
      },
      (err) => {
        console.error('Error fetching credits:', err);
        setUserError('Failed to fetch credits.');
      }
    );

    return () => {
      unsubscribeUsers();
      unsubscribeCredits();
    };
  }, [db]);

  // Combine Users and Credits
  useEffect(() => {
    const combined = users.map((user) => ({
      ...user,
      credits: creditsMap[user.userId] || 0,
    }));
    setUsersWithCredits(combined);
  }, [users, creditsMap]);

  // Handler to Update Credits from Credit Requests
  const handleUpdateCredits = async (requestId, userId, newCredits) => {
    setRequestMessage('');
    setRequestError('');

    if (newCredits === '') {
      setRequestError('Please enter a credit amount.');
      return;
    }

    const creditsNum = parseInt(newCredits, 10);
    if (isNaN(creditsNum) || creditsNum < 0) {
      setRequestError('Credit amount must be a non-negative number.');
      return;
    }

    try {
      const creditRef = db.collection('credits').doc(userId);
      const creditDoc = await creditRef.get();

      if (creditDoc.exists) {
        // Update existing credits
        await creditRef.update({
          credits: creditsNum,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        // Create a new credits document with userId as document ID
        await creditRef.set({
          userId,
          credits: creditsNum,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      // Remove the credit request
      await db.collection('creditRequest').doc(requestId).delete();

      setRequestMessage(`Credits updated to ${creditsNum} for user.`);
    } catch (err) {
      console.error('Error updating credits:', err);
      setRequestError('Failed to update credits. Please try again.');
    }
  };

  // Handler to Manually Update Credits for Any User
  const handleManualUpdateCredits = async (userId, newCredits) => {
    setUserMessage('');
    setUserError('');

    if (newCredits === '') {
      setUserError('Please enter a credit amount.');
      return;
    }

    const creditsNum = parseInt(newCredits, 10);
    if (isNaN(creditsNum) || creditsNum < 0) {
      setUserError('Credit amount must be a non-negative number.');
      return;
    }

    try {
      const creditRef = db.collection('credits').doc(userId);
      const creditDoc = await creditRef.get();

      if (creditDoc.exists) {
        // Update existing credits
        await creditRef.update({
          credits: creditsNum,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        // Create a new credits document with userId as document ID
        await creditRef.set({
          userId,
          credits: creditsNum,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      setUserMessage(`Credits updated to ${creditsNum} for user.`);
    } catch (err) {
      console.error('Error updating credits:', err);
      setUserError('Failed to update credits. Please try again.');
    }
  };

  // -- Local pagination for creditRequests --
  const totalPagesRequests = Math.ceil(creditRequests.length / PAGE_SIZE_REQUESTS);
  const startIndexRequests = pageIndexRequests * PAGE_SIZE_REQUESTS;
  const endIndexRequests = startIndexRequests + PAGE_SIZE_REQUESTS;
  const currentPageRequests = creditRequests.slice(startIndexRequests, endIndexRequests);

  // Row component for Credit Requests
  const CreditRequestRow = React.memo(({ index, style }) => {
    // Hooks always at the top
    const [newCredits, setNewCredits] = useState('');

    const request = currentPageRequests[index];
    if (!request) {
      // Return an empty container if no request
      return <div style={style} />;
    }

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Username:</strong> {request.currentUser}
          <br />
          <strong>Current Credits:</strong>{' '}
          {request.currentCredits !== null ? request.currentCredits : 'N/A'}
          {request.error && <p style={{ color: 'red' }}>{request.error}</p>}
        </div>
        {request.userId && (
          <div style={styles.inputGroup}>
            <input
              type="number"
              placeholder="New Credits"
              value={newCredits}
              onChange={(e) => setNewCredits(e.target.value)}
              style={styles.input}
              min="0"
            />
            <button
              onClick={() => handleUpdateCredits(request.id, request.userId, newCredits)}
              style={styles.button}
            >
              Update
            </button>
          </div>
        )}
      </div>
    );
  });

  // -- Local pagination for usersWithCredits --
  const totalPagesUsers = Math.ceil(usersWithCredits.length / PAGE_SIZE_USERS);
  const startIndexUsers = pageIndexUsers * PAGE_SIZE_USERS;
  const endIndexUsers = startIndexUsers + PAGE_SIZE_USERS;
  const currentPageUsers = usersWithCredits.slice(startIndexUsers, endIndexUsers);

  // Row component for Users with Credits
  const UserCreditsRow = React.memo(({ index, style }) => {
    // Hooks always at the top
    const [newCredits, setNewCredits] = useState('');

    const user = currentPageUsers[index];
    if (!user) {
      return <div style={style} />;
    }

    return (
      <div style={{ ...style, ...styles.listItem }}>
        <div>
          <strong>Username:</strong> {user.username}
          <br />
          <strong>Current Credits:</strong> {user.credits}
        </div>
        <div style={styles.inputGroup}>
          <input
            type="number"
            placeholder="New Credits"
            value={newCredits}
            onChange={(e) => setNewCredits(e.target.value)}
            style={styles.input}
            min="0"
          />
          <button
            onClick={() => handleManualUpdateCredits(user.userId, newCredits)}
            style={styles.button}
          >
            Update
          </button>
        </div>
      </div>
    );
  });

  return (
    <section style={styles.section}>
      <h2 style={{marginBottom:20}}>Credit Requests</h2>

      {/* Pagination Controls for Requests */}
      <div style={styles.pagination}>
        <button
          style={styles.pageButton}
          onClick={() => setPageIndexRequests(Math.max(0, pageIndexRequests - 1))}
          disabled={pageIndexRequests === 0}
        >
          &laquo; Back
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {pageIndexRequests + 1} of {Math.max(totalPagesRequests, 1)}
        </span>
        <button
          style={styles.pageButton}
          onClick={() =>
            setPageIndexRequests(Math.min(totalPagesRequests - 1, pageIndexRequests + 1))
          }
          disabled={pageIndexRequests >= totalPagesRequests - 1 || totalPagesRequests === 0}
        >
          Next &raquo;
        </button>
      </div>

      {loadingRequests ? (
        <p>Loading credit requests...</p>
      ) : currentPageRequests.length === 0 ? (
        <p>No pending credit requests.</p>
      ) : (
        <List
          height={400}
          itemCount={currentPageRequests.length}
          itemSize={120}
          width={'100%'}
        >
          {CreditRequestRow}
        </List>
      )}
      {requestMessage && <p style={styles.message}>{requestMessage}</p>}
      {requestError && <p style={{ ...styles.message, color: 'red' }}>{requestError}</p>}

      <hr style={styles.divider} />

      <h2 style={{marginBottom:20}}>All Users with Credits</h2>

      {/* Pagination Controls for Users */}
      <div style={styles.pagination}>
        <button
          style={styles.pageButton}
          onClick={() => setPageIndexUsers(Math.max(0, pageIndexUsers - 1))}
          disabled={pageIndexUsers === 0}
        >
          &laquo; Back
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {pageIndexUsers + 1} of {Math.max(totalPagesUsers, 1)}
        </span>
        <button
          style={styles.pageButton}
          onClick={() =>
            setPageIndexUsers(Math.min(totalPagesUsers - 1, pageIndexUsers + 1))
          }
          disabled={pageIndexUsers >= totalPagesUsers - 1 || totalPagesUsers === 0}
        >
          Next &raquo;
        </button>
      </div>

      {loadingUsers ? (
        <p>Loading users with credits...</p>
      ) : currentPageUsers.length === 0 ? (
        <p>No users found.</p>
      ) : (
        <List height={400} itemCount={currentPageUsers.length} itemSize={80} width={'100%'}>
          {UserCreditsRow}
        </List>
      )}
      {userMessage && <p style={styles.message}>{userMessage}</p>}
      {userError && <p style={{ ...styles.message, color: 'red' }}>{userError}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '10px',
  },
  input: {
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '120px',
  },
  button: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#28A745',
    color: '#fff',
    cursor: 'pointer',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
  divider: {
    margin: '40px 0',
    border: 'none',
    borderTop: '1px solid #ccc',
  },
  pagination: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  pageButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '6px 12px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    margin: '0 5px',
  },
};

export default GiveCredits;
