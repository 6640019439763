// src/Components/Home/DeleteAccount.js

import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
const sendEmailToAdmins = async () => {
  try {
    // Customize the subject and HTML as needed
    const requestBody = {
      subject: "Flowroom User Deleted Account", 
      html: `
        <h2>Dear Admins,</h2>
        <p>A user has deleted their account.</p>
        <p>Best regards,<br/>Flowroom Team</p>
      `,
    };

    const response = await fetch("https://us-central1-flowroom-fd862.cloudfunctions.net/sendEmailToAdmins", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Email sent successfully:", data);
      // Handle success (e.g., display a success message to the user)
    } else {
      console.error("Error sending email:", data);
      // Handle error (e.g., display an error message to the user)
    }
  } catch (err) {
    console.error("Request failed:", err);
    // Handle fetch error
  }
};
const DeleteAccount = () => {
  const db = firebase.firestore();
  const [username, setUsername] = useState('');
  const [deleteMessage, setDeleteMessage] = useState('');
  const [error, setError] = useState('');

  const deleteUserAndData = async () => {
    const functions = firebase.functions();

    setDeleteMessage('');
    setError('');

    if (!username.trim()) {
      setDeleteMessage('Please provide a username.');
      return;
    }

    try {
      // Confirm deletion
      const confirmDelete = window.confirm(`Are you sure you want to delete the account of ${username.trim()}? This action cannot be undone.`);
      if (!confirmDelete) {
        return;
      }

      // setIsDeleting(true);
      await sendEmailToAdmins()

      // Call the Cloud Function with the username
      const deleteFunction = functions.httpsCallable('deleteUserAndDataByUserName');
      const result = await deleteFunction({ username: username.trim(), redeemedByEmail: firebase.auth().currentUser });

      if (result.data.success) {
        setDeleteMessage(result.data.message);
        setUsername('');
      } else {
        setError('Failed to delete user data. Please try again.');
      }
    } catch (err) {
      console.error('Error deleting user and data:', err);
      setError(err.message || 'Something went wrong, please try again.');
    } finally {
      // setIsDeleting(false);
    }
  };


  // const handleDeleteAccount = async () => {
  //   setDeleteMessage('');
  //   setError('');

  //   if (!username.trim()) {
  //     setDeleteMessage('Please provide a username.');
  //     return;
  //   }

  //   try {
  //     // Fetch user by username
  //     const userSnapshot = await db.collection('users')
  //       .where('username', '==', username.trim())
  //       .limit(1)
  //       .get();

  //     if (userSnapshot.empty) {
  //       setDeleteMessage('User not found.');
  //       return;
  //     }

  //     const userDoc = userSnapshot.docs[0];
  //     const userId = userDoc.data().userId;

  //     // Confirm deletion
  //     const confirmDelete = window.confirm(`Are you sure you want to delete the account of ${username.trim()}?`);
  //     if (!confirmDelete) {
  //       return;
  //     }

  //     // Delete user data from 'users' collection
  //     await db.collection('users').doc(userDoc.id).delete();

  //     // Optionally, delete related data (credits, flows, etc.)
  //     // Implement cascading deletes as per your database structure

  //     setDeleteMessage(`Account of ${username.trim()} has been deleted.`);
  //     setUsername('');
  //   } catch (err) {
  //     console.error("Error deleting account:", err);
  //     setDeleteMessage('Failed to delete account. Please try again.');
  //   }
  // };

  return (
    <section style={styles.section}>
      <h2>Delete Account</h2>
      <div style={styles.inputGroup}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
        />
        <button onClick={deleteUserAndData} style={styles.button}>
          Delete Account
        </button>
      </div>
      {deleteMessage && <p style={styles.message}>{deleteMessage}</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>}
    </section>
  );
};

const styles = {
  section: {
    marginBottom: '40px',
    padding: '20px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  input: {
    flex: '1 1 200px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#DC3545',
    color: '#fff',
    cursor: 'pointer',
    flex: '0 0 auto',
  },
  message: {
    marginTop: '10px',
    color: 'green',
  },
};

export default DeleteAccount;
